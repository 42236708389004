import React from "react";
import Product from "./products/Product";

const Banner = ({prdtType,bgproduct,textCo,borderBlack,
  product: {
    tag,
    imgSize,
    title,
    caption,
    image,
    detail,
    subtitle,
    subCaption,
    detailImg,
    featureOne,
    featureTwo,
    
  },
}) => {
  const hero = { title, caption, tag,imgSize, image, isHero: true };
  return (
    <div className="relative">

      <Product product={hero} bgproduct={bgproduct} prdtType={prdtType} textCo={textCo} borderBlack={borderBlack}/>
      <div className="screenxl flex items-center justify-center p-2 border-none  ">
        <h3 className="w-full text-sm  md:text-base lg:text-2xl font-light  text-center p-3 mt-8 md:mt-20 lg:px-56  ">{detail}</h3>
      </div>
      <div className="trianglebg mt-12 pt-16 md:pt-0 md:mt-20 md:pb-20">
      <div className="md:min-h-[300px] screenxl flex items-center xl:pb-4 ">
        <div className="max-h-[40%] flex flex-col items-center justify-center gap-4 xl:px-[25rem]">
          <h1 className="font-bold text-xl w-full text-center">{subtitle}</h1>
          <h1 className="font-light text-sm md:text-base w-full px-4  text-justify md:text-center">
            {subCaption}
          </h1>
        </div>
      </div>

      <div className="screenxl lg:place-items-start place-items-center  p-6 grid grid-rows-[2fr,1fr,2fr] grid-cols-1 lg:grid-rows-1 lg:grid-cols-3 ">
        {/* feature one row */}

        <ul className="list-none flex flex-col justify-center p-2 gap-4">
          {featureOne.map((feature, idx) => (
            <li key={idx} className="flex flex-col gap-3 p-2">
              <h3 className="font-bold text-xl w-full text-center lg:text-start">
                {feature.title}
              </h3>
              <p className="font-light text-sm md:text-base w-full text-justify ">
                {feature.caption}
              </p>
            </li>
          ))}
        </ul>

        <div className="h-full  w-full flex items-center  justify-center">
          <img src={detailImg} alt={title} className="max-w-[60%]" />
        </div>

        <ul className="list-none  flex flex-col justify-center p-2 gap-4">
          {featureTwo.map((feature, idx) => (
            <li key={idx} className="flex flex-col gap-3 p-2">
              <h3 className="font-bold  text-xl w-full text-center lg:text-start">
                {feature.title}
              </h3>
              <p className="font-light text-sm md:text-base w-full text-justify ">
                {feature.caption}
              </p>
            </li>
          ))}
        </ul>
      </div>
      </div>
    </div>
  );
};

export default Banner;
