import React, { useEffect, useRef } from "react";
import Map from "../Map";
import abtbg from '../../assets/images/abtbg.png'
import mobabtbg from '../../assets/images/mobabtbg.png'
import Navbar from "../Navbar/Navbar";
import NavbarDark from "../Navbar/NavbarDark";

const About = ({setvisible,  activeIndex, setActiveIndex}) => {
  const elemRef = useRef(null);
  setvisible(false)

  useEffect(() => {
    elemRef.current.scrollIntoView();
  }, []);

  const dos = [
    "Research & Development of IOT products",
    "Providing customized product solutions",
    "3D Modeling with 3D printing & laser engraving",
    "Design and development of mechatronics systems",
  ];
  return (
    <div className="min-h-screen" ref={elemRef}>
      
      <div className="">
        <div className="absolute top-0 w-full">
        <NavbarDark  activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
        </div>
      <img src={abtbg} alt="" className="w-full hidden md:block"/>

      </div>
      
      <img src={mobabtbg} alt="" className="w-full md:hidden"/>


      <div className="min-h-[300px]  flex flex-col gap-5 px-6 md:py-20 md:px-80">
        <h1 className="text-lg md:text-4xl font-bold text-justify mt-10 mb-5 md:my-10">
          ABOUT US
        </h1>
        <p className="text-xs md:text-base font-poppins">
          SARON INNOVATURE LABS LLP was founded in 2016, focuses on research,
          development and manufacturing of products in Home Automation and IoT
          sector. We have established a strong reputation for providing high
          quality, reliable products for our customers. Our team of experienced
          R&D team, technicians and support staffs have the expertise in design,
          development and manufacture a wide range of electronic devices. We are
          providing the best customer service and always strive to exceed our
          customers' expectations. Our team of engineers are always on hand to
          provide technical assistance to the valuable customers. We also offer
          comprehensive warranty and maintenance services to ensure that
          products are always functioning optimally. We are located at KINFRA
          INTEGRATED INDUSTRIAL PARK & KINFRA DEFENCE PARK, Palakkad, Kerala.
        </p>
      </div>
      <div className="min-h-[200px] flex-col items-center justify-center flex gap-20 p-10 md:px-28  lg:flex-row">
        <div className="w-[300px] md:w-[500px] sm:h-[300px] lg:h-[200px]  shadow-2xl flex flex-col items-center justify-center p-10">
          <h1 className="text-lg md:text-2xl font-light my-4">Vision</h1>
          <p className="text-xs md:text-sm font-light text-justify">
            To become a global company which delivers top quality energy saving
            and IoT products to our customers.
          </p>
        </div>

        <div className="w-[300px] md:w-[500px] sm:h-[300px] lg:h-[200px] shadow-2xl flex flex-col items-center justify-center p-10">
          <h1 className="text-lg md:text-2xl font-light my-4">Mission</h1>
          <p className="text-xs md:text-sm font-light text-justify">
            Consistently provide quality and good value to our customers through
            best products and services by adopting the latest technology,
            research and development.
          </p>
        </div>
      </div>

      <div className="min-h-[500px] flex flex-col gap-4 pt-24 md:px-48">
        <h1 className="text-2xl md:text-3xl font-medium  my-4 w-full text-center">
          What we do.
        </h1>

        <div className="max-h-[300px] flex-col items-center justify-center flex gap-8  lg:flex-row">
          {dos.map((item, idx) => (
            <div
              key={idx}
              className="w-[300px] shadow-2xl flex  items-center justify-center py-12 px-4 flex justify-center items-center"
            >
              <p className="text-sm font-medium text-center">{item}.</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center my-20">
          <h1 className="text-2xl md:text-4xl font-medium  my-10 w-full text-center">
            Find us on map
          </h1>
          <Map />
        </div>
      </div>
    </div>
  );
};

export default About;
