import msh from "../../assets/images/msh.png";
import pims from "../../assets/images/pims.png";
import vector from "../../assets/images/Vector.png";
import lightbulb from "../../assets/images/lightbulb 1.png";
import thunderbolt from "../../assets/images/thunderbolt 1.png";
import easyi from "../../assets/images/easyi.png";
import msc from "../../assets/images/msc.png";
import BrochureCatalogue from "../products/BrochureCatalogue";
import OtherProducts from "../products/OtherProducts";
import mswb from "../../assets/images/motionsensorwithbox.png";
import pdf from "../../assets/brochures/Motion Sensor.pdf";

import FAQ from "../FAQ";
import { faqs } from "../../constants";
import { Link } from "react-router-dom";
import NavbarDark from "../Navbar/NavbarDark";

const Motion = ({ setvisible, activeIndex, setActiveIndex }) => {
  setvisible(false);

  return (
    <div>
      <div className="absolute top-0 z-[110] w-full">
        <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
      <div className="flex justify-center flex-col md:flex-row items-start md:items-center gap-8 md:gap-20 lg:gap-40 py-48 xl:py-60 bg-[url('assets/images/poweritbg.png')] bg-no-repeat	bg-left-center	bg-[length:80%] bg-[black] md:bg-[length:40%]">
        <div className="relative w-[50%]  mx-auto md:w-[50%] flex justify-center md:ml-20 xl:pl-0">
          <div class="sec1"></div>
          <div class="third1"></div>
          <img src={msh} alt="" className="relative z-100" />
        </div>

        <div className="text-white flex w-[100%] md:w-[80%] flex-col gap-2 md:gap-2 items-start px-12 mt-4 md:mt-0 md:pr-4">
          <h1 className="text-[#AEAEAE] font-light text-xl md:text-2xl">
            POWER-IT
          </h1>
          <b>
            <h1 className="text-3xl font-normal font-poppins md:text-6xl">
              Motion Sensor
            </h1>
          </b>
          <p className="text-sm md:text-base md:text-xl font-light w-[90%] text-[#AEAEAE]">
            Stay one step ahead with our advanced motion sensing technology.
          </p>
          <Link to="https://amzn.eu/d/5wyowMN">
          <button className="bg-[#0BFFC2] mt-10 text-[black] shadow-[0_4px_4px_rgba(0,0,0,0.25)] px-10 rounded-[10px] text-xs md:text-base font-semibold py-3">
            Buy now
          </button></Link>
        </div>
      </div>
      <div className="bg-white flex flex-col-reverse md:flex-row justify-center screenxl items-center py-20 md:py-40">
        <div className="w-[100%] md:w-[60%] lg:w-[60%] flex flex-col items-center text-center md:px-16 xl:px-32 gap-4 md:gap-12">
          <h1 className="text-xl md:text-3xl md:text-4xl lg:text-4xl">
            <b>Say goodbye to fumbling in the dark</b>
          </h1>
          <p className="text-sm text-justify md:text-base font-light ">
            Are you looking for a reliable and convenient way to enhance the
            efficiency of lighting of your home or business? Motion sensor is
            the solution you've been searching for.
          </p>
          <p className="text-sm md:text-base text-justify font-light">
            The motion sensor is a device that uses advanced technology to
            detect movement in a specific area. When movement is detected, It
            automatically turns on the load that is connected to it. It is
            compatible with a wide range of devices and systems, making it a
            versatile and convenient solution for enhancing your home.
          </p>
        </div>
        <div className="w-[100%] md:w-[40%] flex justify-center">
          <img src={pims} className="w-[90%] md:w-[80%]" alt="" />
        </div>
      </div>

      <div className="bg-black py-20 md:py-40 px-8 md:px-16 lg:px-32 text-white">
        <div className="flex flex-col gap-12 md:gap-0 md:flex-row justify-around">
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={vector} alt="" />
            <h2 className="text-lg md:text-xl">Detection Range</h2>
            <p className="text-sm text-center text-[#AEAEAE] md:text-base">
              &lt; 140 degree Up to 7meters
            </p>
          </div>
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={lightbulb} alt="" />
            <h2 className="text-lg md:text-xl">Load</h2>
            <p className="text-sm text-[#AEAEAE] md:text-base">
              1000 Watts maximum
            </p>
          </div>
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={thunderbolt} alt="" />
            <h2 className="text-lg md:text-xl">Power Consumption</h2>
            <p className="text-sm  text-[#AEAEAE] md:text-base text-center">
              0.25 Watts(when load is active 0.375 Watts)
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col screenxl pt-40 pb-20 xl:flex-row justify-center items-center gap-12 md:gap-20">
        <div className="w-[90%] md:w-[50%] flex justify-center items-center">
          <img src={mswb} alt="" />
        </div>
        <div className="flex flex-col gap-5 w-[100%] xl:w-[50%]">
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Automation</h1>
            <p className="text-sm md:text-md">
              The motion sensor takes care of turning your lights on and off for
              you, so you don't have to worry about it.
            </p>
          </div>
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Energy efficiency</h1>
            <p className="text-sm md:text-md">
              The motion sensor can be used to turn off lights or appliances
              when no movement is detected, helping you save energy and money.
            </p>
          </div>
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Convenience</h1>
            <p className="text-sm md:text-md">
              The motion sensor allows you to set up automated actions that make
              your life easier, such as turning on the lights when you enter a
              room.
            </p>
          </div>
        </div>
      </div>

      <div className=" flex justify-center md:px-16 xl:px-20 py-20 md:py-40">
        <div className="w-[100%] flex flex-col-reverse md:flex-row items-center justify-center gap-8 md:gap-20 xl:gap-40">
          <div className="w-[100%] md:w-[60%] flex justify-center xl:justify-end ">
            <img src={easyi} alt="" />
          </div>
          <div className="w-[100%] md:w-[40%] ">
            <h1 className="text-2xl xl:text-3xl text-center xl:text-left ">
              Easy installation
            </h1>
          </div>
        </div>
      </div>

      <div className=" flex justify-center px-4 md:px-20 pb-20">
        <div className="w-[100%] flex flex-col md:flex-row items-center justify-center gap-20 xl:gap-40">
          <div className="w-[100%] md:w-[60%] ">
            <h1 className="text-lg sm:text-xl xl:text-3xl text-bold text-center xl:pl-60">
              Ensure that the motion sensor is faced towards the area where
              human movements usually happen
            </h1>
          </div>
          <div className="w-[80%] md:w-[60%] flex justify-start px-20 ">
            <img src={msc} alt="" />
          </div>
        </div>
      </div>

      <BrochureCatalogue file={pdf} name="Motion sensor Brochure" />
      <OtherProducts index={4} />
      <FAQ faq={faqs[2]} />
    </div>
  );
};

export default Motion;
