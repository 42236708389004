import product1 from "./assets/images/product1.png";
import product2 from "./assets/images/product2.png";
import product3 from "./assets/images/product3.png";
import product4 from "./assets/images/product4.png";
import product5 from "./assets/images/product5.png";

import product1Draw from "./assets/images/product1Draw.png";
import product2Draw from "./assets/images/product2Draw.png";
import product3Draw from "./assets/images/product3Draw.png";

import product1SetUp from "./assets/images/product1SetUp.png";
import product2SetUp from "./assets/images/product2SetUp.png";

import product1Spec from "./assets/images/product1Spec.png";
import product1Spec2 from "./assets/images/product1Spec2.png";
import product1Spec3 from "./assets/images/product1Spec3.png";
import prdt1 from './assets/lorawwlc.png'
import prdt2 from './assets/waterlevelcontroller.png'
import prdt3 from './assets/mobileoperatedpump.png'


export const HomeSliderInfo = [
  {
    tag:"MOTCON V2",
    title: "LoRa-WIRLESS WATER LEVEL CONTROLLER",
    desc: "No need to worry about the water in your tank, even if it is located distant from the pump set. The system control the pump set ON/OFF with respect to the tank water levels.",
    image: prdt1,
    points: [
      "Wireless operation using LoRa technology",
      "Equipped with an easy-to-read LCD display",
      "Equipped with voltage fluctuation and dry run protection."
    ],
    path:"/products/lora-wireless-water-tank-level-controller"
  },
  {
    tag:"MOTCON V2",

    title: "WIRED WATER LEVEL CONTROLLER ",
    desc:"The advanced control system is designed to automatically control the water level in your tank and switch ON/OFF with respect to water levels inside the tank.",
    image:prdt2,
    points: [
      "Micro controller technology",
      "Equipped with an easy-to-read LCD display",
      "Equipped with voltage fluctuation and dry run protection."
    ],
    path:"/products/water-tank-level-controller-wired"
  },
  {
    tag:"MOTCON V2",

    title:"MOBILE PHONE OPERATED PUMP CONTROLLER",
    desc:"The ultimate solution for control your water pumping from anywhere in the world. This advanced device is designed to switch ON/OFF the pump set from the mobile phone with out the use of internet.",
    image:prdt3,
    points: [
      "Based on GSM technology",
      "Equipped with an easy-to-read LCD display",
      "Equipped with voltage fluctuation and dry run protection."
    ],
    path:"/products/mobile-phone-operated-pump-controller"
  }
];

export const productInfo = [
  {
    title: "LoRa-Wireless Water Level Controller",
    caption:
      "Motcon V2 wireless is specially designed for wireless control  of pump set with respect to tank water levels with a long  range up to 4 kilometers.",
    tag: "MOTCON V2",
    image: product1,
    bg: "bg-grey-two",
    imgBg: "bg-grey-one",
    flex: false,
    path: "/products/lora-wireless-water-tank-level-controller",
    imgSize: "lg",
  },

  {
    title: "Water Level Controller",
    caption:
      "The ultimate solution for managing your water tank. The advanced  control system is designed to automatically control the water level in  your tank and switch ON/OFF with respect to water levels inside the  tank. It helps you to optimize the water usage, reduce electrical energy consumption and extend the lifespan of the pump set.",
    tag: "MOTCON V2",
    image: product2,
    bg: "bg-grey-one",
    imgBg: "bg-grey-two",
    flex: true,
    path: "/products/water-tank-level-controller-wired",
    imgSize: "sm",
  },
  {
    title: "Mobile Phone Operated Pump Controller",
    caption:
      "Mobile phone operated pump controller, the ultimate solution for control your water pumping from anywhere in the world.",
    tag: "MOTCON V2",
    image: product3,
    bg: "bg-grey-two",
    imgBg: "bg-grey-one",
    flex: false,
    path: "/products/mobile-phone-operated-pump-controller",
    imgSize: "lg",
  },
  {
    title: "Day-Night Sensor",
    caption:
      "This day night sensor can switch on lights at parking, garden, sign board, gate, street light when dark falls and  switch off in the morning automatically.",
    tag: "POWER IT",
    image: product4,
    bg: "bg-grey-one",
    imgBg: "bg-grey-two",
    flex: true,
    path: "/products/day-night-sensor",
    imgSize: "sm",
  },
  {
    title: "Motion Sensor",
    caption:
      "Say goodbye to leaving the lights ON. Now, you can set  them to turn OFF when a room is unoccupied.",
    tag: "POWER IT",
    image: product5,
    bg: "bg-grey-two",
    imgBg: "bg-grey-one",
    flex: false,
    path: "/products/motion-sensor",
    imgSize: "sm",
  },
];

export const comboInfo = [
  {
    title: "Day-Night Sensor",
    caption:
      "This day night sensor can switch on lights at parking, garden, sign board, gate, street light when dark falls and  switch off in the morning automatically.",
    image: product4,
    bg: "bg-grey-two",
    imgBg: "bg-grey-one",
    flex: false,
    qty: 1,
  },
  {
    title: "Water Level Controller",
    caption:
      "The ultimate solution for managing your water tank. The advanced  control system is designed to automatically control the water level in  your tank and switch ON/OFF with respect to water levels inside the  tank. It helps you to optimize the water usage, reduce electrical energy consumption and extend the lifespan of the pump set.",
    image: product2,
    bg: "bg-grey-one",
    imgBg: "bg-grey-two",
    flex: true,
    qty: 1,
  },
  {
    title: "Motion Sensor",
    caption:
      "Say goodbye to leaving the lights ON. Now, you can set  them to turn OFF when a room is unoccupied.",
    image: product5,
    bg: "bg-grey-two",
    imgBg: "bg-grey-one",
    flex: false,
    qty: 4,
  },
];

export const singleProductInfo = [
  {
    tag: "MOTCON V2",
    imgSize: "lg",

    title: "LoRa-Wireless Water Level Controller",
    caption:
      "Stay in control, no matter the distance - with our LoRa based wireless water level controller",
    image: product1,
    detail:
      "MOTCON V2 -LoRa based wireless water level controller is a smart device that saves time, fresh water and energy. No need to worry about the water in your tank, even if it is located distant from the pump set. The system control the pump set ON/OFF with respect to the tank water levels. Also monitors the live AC voltage and water pumping to ensure trouble free and safe operation of the pumping system.",
    subtitle: "FULLY AUTOMATIC",
    subCaption:
      "You can enjoy the benefits of our wireless water level controller without having to worry about manually turning the pump ON and OFF. It saves your valuable time, reduce water and electricity wastage. ",
    detailImg: product1Draw,
    featureOne: [
      {
        title: "LoRa TECHNOLOGY",
        caption:
          "Wireless operation using LoRa allows for easy control of water level, even from a distance up to 4Km. It Uses Indian license free band-866MHz.",
      },
      {
        title: "COMPATIBILITY",
        caption:
          "This system is compatible with all types of single phase and three phase motor pumps like self priming pump, open-well pump, submersible pumps etc",
      },
      {
        title: "VOLTAGE MONITORING",
        caption:
          "This system monitors live AC voltage to detect any low or high voltage occurrence. The system switch off the pump set if it detects any voltage fluctuations.",
      },
      {
        title: "MAGNETIC FLOAT SENSING",
        caption:
          "Specially designed reliable magnetic float sensors ensures that the water level is always accurately monitored. It is easy to install and maintain, with no need for complicated wiring or calibration.",
      },
    ],
    featureTwo: [
      {
        title: "TEXTUAL DISPLAY",
        caption:
          "The controller is equipped with an easy-to-read LCD display, which provides real-time information of transmitter Battery level, live AC voltage, pump status, tank level status and signal reception status.. The display also shows any errors or alerts that may arise, allowing you to quickly troubleshoot any issues.",
      },
      {
        title: "DRY-RUN PROTECTION",
        caption:
          "Dry running protection is intended to prevent the pump from operating without water. If a specified limit is undershot, the protection function will ensure that the system is shut down completely and the respective message is displayed. ",
      },
      {
        title: "SOLAR POWERED TRANSMISSION",
        caption:
          "The low power long- range wireless transmitter uses Li-ion battery as power source. The battery is charged through solar panel with charge controller.The transmitter can with stand 60 day’s back up in the absence of sunlight .",
      },
    ],
  },
  {
    tag: "MOTCON V2",
    imgSize: "sm",

    title: "Water Level Controller - Wired",
    caption: "Set it and forget it: Fully Automatic Water Level Control",
    image: product2,
    detail:
      "Introducing Automatic Water Level Controller, the ultimate solution for managing your water tank. The advanced control system is designed to automatically control the water level in your tank and switch ON/OFF with respect to water levels inside the tank. It helps you to optimize the water usage, reduce electrical energy consumption and extend the lifespan of the pump set.",
    subtitle: "FULLY AUTOMATIC",
    subCaption:
      "You can enjoy the benefits of our wireless water level controller without having to worry about manually turning the pump ON and OFF. It saves your valuable time, reduce water and electricity wastage. ",
    detailImg: product2Draw,
    featureOne: [
      {
        title: "MICRO CONTROLLER TECHNOLOGY",
        caption:
          "Intelligent 8-bit micro controller ensures reliable and accurate system operations and controls.",
      },
      {
        title: "DRY-RUN PROTECTION",
        caption:
          "Dry running protection is intended to prevent the pump from operating without water. If a specified limit is undershot, the protection function will ensure that the system is shut down completely and the respective message is displayed. ",
      },
      {
        title: "TEXTUAL DISPLAY",
        caption:
          "The controller is equipped with an easy-to-read LCD display, which provides real-time information of transmitter Battery level, live AC voltage, pump status, tank level status and signal reception status.. The display also shows any errors or alerts that may arise, allowing you to quickly troubleshoot any issues.",
      },
    ],
    featureTwo: [
      {
        title: "COMPATIBILITY",
        caption:
          "This system is compatible with all types of single phase and three phase motor pumps like self priming pump, open-well pump, submersible pumps etc",
      },
      {
        title: "MAGNETIC FLOAT SENSORS",
        caption:
          "Specially designed reliable magnetic float sensors ensures that the water level is always accurately monitored. It is easy to install and maintain, with no need for complicated wiring or calibration.",
      },
      {
        title: "VOLTAGE MONITORING",
        caption:
          "This system monitors live AC voltage to detect any low or high voltage occurrence. The system switch off the pump set if it detects any voltage fluctuations.",
      },
    ],
  },

  {
    tag: "MOTCON V2",
    imgSize: "lg",

    title: "Mobile Phone Operated Pump Controller",
    caption:
      "Set it & control it : Control your pump-set from anywhere in the world ",
    image: product3,
    detail:
      "Introducing mobile phone operated pump controller, the ultimate solution for control your water pumping from anywhere in the world. This advanced device is designed to switch ON/OFF the pump set from the mobile phone with out the use of internet. The system has auto back SMS messaging for providing feed back and error free operations.",
    subtitle: "FULLY REMOTE OPERATION",
    subCaption:
      "With the use of GSM network, the system can control control operation of the pump set from a mobile immobile phone from anywhere in the world. ",
    detailImg: product3Draw,
    featureOne: [
      {
        title: "SCHEDULED AUTO -OFF FUNCTION",
        caption:
          "The user can set timer via SMS, thus the system can switched-off the pump set once the timer expires. And the system will send SMS to the user mobile as confirmation, with the timer values. ",
      },
      {
        title: "AC VOLTAGE PROTECTION",
        caption:
          "The auto switch OFF the pump set, if the voltage drops to low/high values. And will send notification SMS to user mobile. ",
      },
      {
        title: "AUTO RESUME FUNCATION",
        caption:
          "If the power goes off during pumping ,the system will auto switch ON the pump set during power resume .And run the pump only for remaining timer value.",
      },
      {
        title: "DRY RUN PROTECTION",
        caption:
          "The system has option to connect dry run sensor , then the system will be able auto switch OFF the pump set, if the the sensor detects absence of water after switched ON via phone call.. And will send notification SMS to user mobile. ",
      },
    ],
    featureTwo: [
      {
        title: "COMPATIBILITY",
        caption:
          "This system is compatible with all types of single phase and three phase motor pumps like self priming pump, open-well pump, submersible pumps etc",
      },
      {
        title: "TEXTUAL DISPLAY",
        caption:
          "The controller is equipped with an easy-to-read LCD display, which provides real-time information live AC voltage, pump status,and signal reception status.The display also shows any errors or alerts that may arise, allowing you to quickly troubleshoot any issues.",
      },
      {
        title: "MICRO CONTROLLER TECHNOLOGY",
        caption:
          "Intelligent 8-bit micro controller ensures reliable and accurate system operations and controls.",
      },
      {
        title: "USER PROTECTION",
        caption:
          "The system can be controlled only from 5 different phone numbers. Unknown call or SMS can not controls the system.",
      },
    ],
  },
];

export const ProductSetUps = [
  {
    image: product1SetUp,
    setUp: [
      "CONTROL PANEL",
      "TRANSMITTER MODULE",
      "DRY-RUN SENSOR",
      "SOLAR PANEL",
      "MAGNETIC FLOAT SENSOR",
    ],
  },
  {
    image: product2SetUp,
    setUp: ["CONTROL PANEL", "DRY-RUN SENSOR", "MAGNETIC FLOAT SENSOR"],
  },
];

export const productSpecs = [
  {
    image: product1Spec,
    title: "Wireless receiver-controller data sheet",
    specs: [
      {
        feature: "Mode of operation",
        caption: ["Wireless LoRa technology"],
      },
      {
        feature: "Frequency",
        caption: ["866 MHz"],
      },
      {
        feature: "Control characteristics",
        caption: ["Wireless & Sump Tank"],
      },
      {
        feature: "Control method",
        caption: ["Manual/ Auto"],
      },
      {
        feature: "Rated output power",
        caption: ["0.37.KW - 1.86KW(0.5HP-2.5HP)"],
      },
      {
        feature: "Rated input power",
        caption: ["AC 230V/50Hz Single phase"],
      },
      {
        feature: "Trip voltage of High voltage",
        caption: ["260V"],
      },
      {
        feature: "Trip voltage of Low voltage",
        caption: ["175V"],
      },
      {
        feature: "Trip response High/Low voltage",
        caption: ["<10 sec"],
      },
      {
        feature: "Trip response time of Dry run",
        caption: ["5 sec"],
      },
      {
        feature: "Recovery time of voltage",
        caption: ["10 sec"],
      },
      {
        feature: "Recovery time of Dry run",
        caption: ["<10 sec"],
      },
      {
        feature: "Trip response High/Low voltage",
        caption: ["Manual"],
      },
      {
        feature: "Output Load",
        caption: ["All single & 3phase panels"],
      },
      {
        feature: "Protection functions",
        caption: ["Dry run", "High Voltage", "Low Voltage", "Transient surge"],
      },
      {
        feature: "Unit dimention",
        caption: ["155x140x56 mm"],
      },
      {
        feature: "Weight",
        caption: ["1100 gms"],
      },
    ],
  },
  {
    image: product1Spec2,
    title: "Wireless transmitter data sheet",
    specs: [
      {
        feature: "Mode of operation",
        caption: ["Wireless-LoRa Technology"],
      },
      {
        feature: "Frequency",
        caption: ["866MHz"],
      },
      {
        feature: "Antenna power",
        caption: ["5dBi"],
      },
      {
        feature: "Sensing method",
        caption: ["Magnetic float method"],
      },
      {
        feature: "Sensing parameter",
        caption: ["Tank low, Tank full, Dryrun"],
      },
      {
        feature: "Solar panel",
        caption: ["12v 5W"],
      },
      {
        feature: "Battery",
        caption: ["3.7v Li-ion single cell"],
      },
      {
        feature: "Battery backup (without sun light)",
        caption: ["40 Days"],
      },
      {
        feature: "Enclosure material",
        caption: ["Polycarbonate"],
      },
      {
        feature: "Protection",
        caption: ["IP65"],
      },
      {
        feature: "Unit dimension",
        caption: ["100x100x70"],
      },
      {
        feature: "Weight",
        caption: ["235gm"],
      },
    ],
  },
  {
    image: product1Spec3,
    title: "Sensors set data sheet",
    specs: [
      {
        feature: "Sensing parameter",
        caption: ["Tank low, Tank full, Dry-run"],
      },
      {
        feature: "Water level sensing ",
        caption: ["Magnetic read switch"],
      },
      {
        feature: "Dry-run sensing",
        caption: ["Stainless steel probe"],
      },
      {
        feature: "Dry-run sensor wire length",
        caption: ["1.5 meter"],
      },
      {
        feature: "Low level sensor wire length",
        caption: ["2 meter"],
      },
      {
        feature: "High level sensor wire length",
        caption: ["1 meter"],
      },
      {
        feature: "Tank mounting ",
        caption: ["Two 5mm self screw"],
      },
      {
        feature: "Mounting socket material",
        caption: ["Poly propylene"],
      },
      {
        feature: "Mounting socket Protection",
        caption: ["IP65"],
      },
      {
        feature: "Total unit length",
        caption: ["12 meter"],
      },
      {
        feature: "Weight",
        caption: ["460 gm"],
      },
    ],
  },
];

export const otherProducts = [
  [
    {
      image: product2,
      path: "/products/water-tank-level-controller-wired",
      title: "Water Level Controller",
    },
    {
      image: product3,
      path: "/products/mobile-phone-operated-pump-controller",
      title: "Mobile Phone Operated Pump Controller",
    },
    {
      image: product4,
      path: "/products/day-night-sensor",
      title: "Day-Night Sensor",
    },
    {
      image: product5,
      path: "/products/motion-sensor",
      title: "Motion Sensor",
    },
  ],
  [
    {
      image: product1,
      path: "/products/lora-wireless-water-tank-level-controller",
      title: "LoRa-Wireless Water Level Controller",
    },
    {
      image: product3,
      path: "/products/mobile-phone-operated-pump-controller",
      title: "Mobile Phone Operated Pump Controller",
    },
    {
      image: product4,
      path: "/products/day-night-sensor",
      title: "Day-Night Sensor",
    },
    {
      image: product5,
      path: "/products/motion-sensor",
      title: "Motion Sensor",
    },
  ],
  [
    {
      image: product1,
      path: "/products/lora-wireless-water-tank-level-controller",
      title: "LoRa-Wireless Water Level Controller",
    },
    {
      image: product2,
      path: "/products/water-tank-level-controller-wired",
      title: "Water Level Controller",
    },

    {
      image: product4,
      path: "/products/day-night-sensor",
      title: "Day-Night Sensor",
    },
    {
      image: product5,
      path: "/products/motion-sensor",
      title: "Motion Sensor",
    },
  ],
  [
    {
      image: product1,
      path: "/products/lora-wireless-water-tank-level-controller",
      title: "LoRa-Wireless Water Level Controller",
    },
    {
      image: product2,
      path: "/products/water-tank-level-controller-wired",
      title: "Water Level Controller",
    },
    {
      image: product3,
      path: "/products/mobile-phone-operated-pump-controller",
      title: "Mobile Phone Operated Pump Controller",
    },

    {
      image: product5,
      path: "/products/motion-sensor",
      title: "Motion Sensor",
    },
  ],
  [
    {
      image: product1,
      path: "/products/lora-wireless-water-tank-level-controller",
      title: "LoRa-Wireless Water Level Controller",
    },
    {
      image: product2,
      path: "/products/water-tank-level-controller-wired",
      title: "Water Level Controller",
    },
    {
      image: product3,
      path: "/products/mobile-phone-operated-pump-controller",
      title: "Mobile Phone Operated Pump Controller",
    },
    {
      image: product4,
      path: "/products/day-night-sensor",
      title: "Day-Night Sensor",
    },
  ],
];

export const faqs = [
  [
    {
      q: "Can I use this device for borewell water source?",
      a: "Yes. You can",
    },
    {
      q: "Can I control this device from my mobile phone?",
      a: " Yes. We have Gsm operated service also.",
    },
    {
      q: "How can I use the warranty services? ",
      a: "You can contact the customer support team",
    },
    {
      q: "My pump set is 2km away from my water tank.. can I use wireless water level controller?",
      a: " wireless water level controller can use up to 4km distance",
    },
    {
      q: "How long is the warranty of the product?",
      a: "One year",
    },
    {
      q: "Is this device fully automatic or need manual support?",
      a: " it is automatic . if you want to use it manually then there is a switch",
    },
    {
      q: "Whether it has any voltage protection?",
      a: "Yes",
    },
    {
      q: "Do you have any installation service ?",
      a: " yes additional installation cost may be added",
    },
  ],
  [
    {
      q: "How can I buy this?",
      a: "You can buy it from amazon or contact directly for any bulk order/single order.",
    },
    {
      q: "How to install the product?",
      a: "The step by step guidelines to install the product are given in the manual.",
    },
    {
      q: "Is this water proof? means can sensor fix at open place during rainy season?",
      a: "Yes, But For better result and good performance, kindly fix in the area where there is no direct rainfall",
    },
    {
      q: "How many lights can be connected to one sensor?",
      a: "The maximum load that can be connected to this device is 1000 watts. Hence the sum of the power consumed by lights should be less than 1000",
    },
  ],
  [
    {
      q: "How can I buy this?",
      a: "You can buy it from amazon or contact directly for any bulk order/single order.",
    },
    {
      q: "Where is the best place to put a motion sensor?",
      a: "The best placement for a motion sensor is in a corner of the room that has a good viewpoint of your entryways and easily detect any changes in the room",
    },
    {
      q: "Do motion sensor work in the dark?",
      a: "Yes, Motion sensors do work in complete darkness",
    },
    {
      q: "Can I use motion sensor switch for my bathroom light and exhaust fan?",
      a: "yes. You can use one motion sensor switch for both light and exhaust fan",
    },
  ],
];
