import msh from "../../assets/images/product4.png";
import pims from "../../assets/images/pims.png";
import vector from "../../assets/images/Vector.png";
import lightbulb from "../../assets/images/lightbulb 1.png";
import thunderbolt from "../../assets/images/thunderbolt 1.png";
import easyi from "../../assets/images/lightsen.png";
import msc from "../../assets/images/daynig.png";
import BrochureCatalogue from "../products/BrochureCatalogue";
import OtherProducts from "../products/OtherProducts";
import FAQ from "../FAQ";
import mswb from "../../assets/images/lswb.png";
import pdf from "../../assets/brochures/Light Sensor.pdf";

import { faqs } from "../../constants";
import { Link } from "react-router-dom";
import NavbarDark from "../Navbar/NavbarDark";

const Daynight = ({ setvisible , activeIndex, setActiveIndex}) => {
  setvisible(false);

  return (
    <div>
      <div className="absolute top-0 z-[110] w-full">
        <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
      <div className="flex justify-center flex-col md:flex-row items-start md:items-center gap-8 w-full  py-48 xl:py-60 bg-[url('assets/images/poweritbg.png')] bg-no-repeat	bg-left-center	bg-[length:80%] bg-[black] md:bg-[length:40%]">
        <div className="relative w-[50%]  mx-auto md:w-[50%] flex justify-center md:ml-20 xl:pl-0">
          <div class="sec1"></div>
          <div class="third1"></div>
          <img src={msh} alt="" className="relative z-100" />
        </div>

        <div className="text-white flex w-[100%] md:w-[80%] flex-col gap-2 md:gap-2 items-start px-12 mt-4 md:mt-0 md:pr-4">
          <h1 className="text-[#AEAEAE] font-light text-xl md:text-2xl">
            POWER-IT
          </h1>
          <b>
            <h1 className="text-3xl font-normal font-poppins md:text-6xl">
              Day-Night Sensor
            </h1>
          </b>
          <p className="text-base md:text-xl font-light w-[90%] text-[#AEAEAE]">
            Experience the convenience and efficiency of automatic lighting
          </p>
          <Link to="https://amzn.eu/d/1rtM8hJ">
          <button className="bg-[#0BFFC2] mt-10 text-[black] shadow-[0_4px_4px_rgba(0,0,0,0.25)] px-10 rounded-[10px] text-xs md:text-base font-semibold py-3">
            Buy now
          </button>
          </Link>
          
        </div>
      </div>
      <div className="bg-white flex flex-col-reverse md:flex-row justify-center screenxl items-center py-20 md:py-40">
        <div className="w-[100%] md:w-[60%] lg:w-[60%] flex flex-col items-center text-center md:px-16 xl:px-32 gap-4 md:gap-12">
          <h1 className="text-xl md:text-3xl md:text-4xl lg:text-4xl">
            <b>Are you coming to a dark house?</b>
          </h1>
          <p className="text-sm text-justify md:text-base font-light ">
            The Day Night Sensor is here to solve your lighting woes. With the
            Day Night Sensor, you can connect any light to the device and it
            will automatically turn on when it senses darkness.
          </p>
          <p className="text-sm md:text-base text-justify font-light">
            The Day Night Sensor is a smart device that uses advanced technology
            to accurately detect the level of light. When it senses darkness, it
            automatically turns on any light that is connected to it. The Day
            Night Sensor is compatible with any light, making it a versatile and
            convenient solution for illuminating your home or business.
          </p>
        </div>
        <div className="w-[100%] md:w-[40%] flex justify-center">
          <img src={pims} className="w-[90%] md:w-[80%]" alt="" />
        </div>
      </div>

      <div className="bg-black py-20 md:py-40 px-8 md:px-16 lg:px-32 text-white">
        <div className="flex flex-col gap-12 md:gap-0 md:flex-row justify-around">
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={vector} alt="" />
            <h2 className="text-lg md:text-xl">Switching Light Intensity</h2>
            <p className="text-sm text-center text-[#AEAEAE] md:text-base">
              &lt; ON when sunlight intensity is less than 20lx, OFF when it is
              more than 40lx
            </p>
          </div>
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={lightbulb} alt="" />
            <h2 className="text-lg md:text-xl">Load</h2>
            <p className="text-sm text-[#AEAEAE] md:text-base">
              1000 Watts maximum
            </p>
          </div>
          <div className="flex flex-col items-center gap-3 md:w-[30%]">
            <img src={thunderbolt} alt="" />
            <h2 className="text-lg md:text-xl">Power Consumption</h2>
            <p className="text-sm  text-[#AEAEAE] md:text-base text-center">
              0.25 Watts(when load is active 0.375 Watts)
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col screenxl pt-40 pb-20 xl:flex-row justify-center items-center gap-12 md:gap-20">
        <div className="w-[90%] md:w-[50%] flex justify-center items-center">
          <img src={mswb} alt="" />
        </div>
        <div className="flex flex-col gap-5 w-[100%] xl:w-[50%]">
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Automation</h1>
            <p className="text-sm md:text-md">
              The Day Night Sensor takes care of turning your lights on and off
              for you, so you don't have to worry about it.
            </p>
          </div>
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Energy efficiency</h1>
            <p className="text-sm md:text-md">
              By only turning on the lights when they're needed, the Day Night
              Sensor helps you save money on your energy bill and do your part
              for the environment.
            </p>
          </div>
          <div className="rounded-[10px] bg-[#d9d9d959] flex flex-col gap-3 p-4">
            <h1 className="text-lg md:text-xl">Convenience</h1>
            <p className="text-sm md:text-md">
              The Day Night Sensor makes it easy to keep your home or business
              illuminated - no need to remember to turn the lights on or off.
            </p>
          </div>
        </div>
      </div>

      <div className=" flex justify-center md:px-16 xl:px-20 py-20 md:py-40">
        <div className="w-[100%] flex flex-col-reverse md:flex-row items-center justify-center gap-8 md:gap-20 xl:gap-40">
          <div className="w-[100%] md:w-[60%] flex justify-center xl:justify-end ">
            <img src={easyi} alt="" />
          </div>
          <div className="w-[100%] md:w-[40%] ">
            <h1 className="text-2xl xl:text-3xl text-center xl:text-left ">
              Easy installation
            </h1>
          </div>
        </div>
      </div>

      <div className=" flex justify-center px-4 md:px-20 pb-20">
        <div className="w-[100%] flex flex-col md:flex-row items-center justify-center gap-20 xl:gap-40">
          <div className="w-[100%] md:w-[60%] ">
            <h1 className="text-lg sm:text-xl xl:text-3xl text-bold text-center xl:pl-60">
              Ensure that the Day-night sensor is not influenced in any way by
              the connected bulb’s light
            </h1>
          </div>
          <div className="w-[80%] md:w-[60%] flex justify-start px-20 ">
            <img src={msc} alt="" />
          </div>
        </div>
      </div>

      <BrochureCatalogue file={pdf} name="Light Sensor Brochure" />
      <OtherProducts index={3} />
      <FAQ faq={faqs[1]} />
    </div>
  );
};

export default Daynight;
