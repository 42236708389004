import { useEffect, useRef } from "react";
import {
  singleProductInfo,
  ProductSetUps,
  productSpecs,
  faqs,
} from "../../constants";
import Banner from "../Banner";
import waterTanker from "../../assets/images/water-tanker.png";
import ProductSetUp from "../products/ProductSetUp";
import ProductSpec from "../products/ProductSpec";
import BrochureCatalogue from "../products/BrochureCatalogue";
import OtherProducts from "../products/OtherProducts";
import FAQ from "../FAQ";
import pdf from "../../assets/brochures/Mv2-Wireless_flyer.pdf";
import NavbarDark from "../Navbar/NavbarDark";

const Lora = ({setvisible, activeIndex, setActiveIndex}) => {
  setvisible(false)
  const elemRef = useRef(null);

  useEffect(() => {
    elemRef.current.scrollIntoView();
  }, []);
  return (
    <div ref={elemRef}>
      <div className="absolute z-[110] w-full">
        <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
      <Banner product={singleProductInfo[0]} prdtType="lora" bgproduct="bg-1" />
      <div className="w-full screenxl flex lg:my-40">
        <img
          src={waterTanker}
          alt="water tanker"
          className="w-[100%] md:w-[90%] mx-auto h-full object-cover "
        />{" "}
      </div>
      <ProductSetUp product={ProductSetUps[0]} />
      <ProductSpec  product={productSpecs[0]} />
      <ProductSpec product={productSpecs[1]} isFlex />
      <ProductSpec product={productSpecs[2]} />
      <BrochureCatalogue
        file={pdf}
        name="WIREless AUTOMATIC WATER level CONTROLLER Brochure.pdf"
      />
      <OtherProducts index={0} />
      <FAQ faq={faqs[0]} />
    </div>
  );
};

export default Lora;
