import React from "react";

const BrochureCatalogue = ({ file, name }) => {
  return (
    <div className="bg-[#2C2C2C] screenxl grid grid-rows-2 grid-cols-1 place-items-center py-32 gap-5 ">
      <h1 className="font-light text-2xl text-[white] md:text-4xl w-full text-center">
        Product Brochure & Catalogue
      </h1>
      <a
        href={file}
        className="cursor-pointer duration-300 font-semibold hover:bg-black text-black hover:text-white outline-none bg-white rounded-[5px] shad px-12 py-4"
        download={name}
      >
        Download
      </a>
    </div>
  );
};

BrochureCatalogue.defaultProps = {
  file: null,
  name: null,
};

export default BrochureCatalogue;
