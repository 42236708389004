import React from "react";

const ProductSetUp = ({ product: { image, setUp } }) => {
  return (
  <div className="flex flex-col-reverse sm:flex-row justify-center mt-20 px-12 lg:px-0 lg:mt-0 mb-12 md:mb-40">
    <div className="w-[100%]  lg:w-[60%] flex flex-col justify-center items-end lg:items-center">
      <h1 className="font-bold text-4xl 2xl:text-5xl hidden lg:flex">Wrapped in Elegance</h1>
    <ul className=" flex flex-wrap justify-center pt-12 gap-2 md:gap-4 w-[100%] lg:w-[70%]">
        {setUp.map((item, idx) => (
          <li
            key={idx}
            className=" bg-[black] text-[10px] sm:text-[12px] lg:text-[16px] rounded-full px-8 py-3 text-[white] mb-4"
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
    <div className="w-[100%] lg:w-[40%] flex md:blcok justify-center lg:justify-start xl:justify-center items-center">
    <img
          src={image}
          alt="set up"
          className="w-[70%] md:w-[70%] h-auto rounded-full shadow-xl"
        />
    </div>
  </div>



 
  );
};

export default ProductSetUp;
