import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Product = ({ product, num, prdtType, combo, bgproduct, showTagLine, textC, textCo,borderBlack }) => {
  const { title, caption, tag, image, bg, imgBg, flex, isHero, path, imgSize } =
    product;
  const navigate = useNavigate();

  const onClickHandler = (isHero) => {
    if (!isHero) {
      if (path) navigate(path);
    }
    if (isHero) {
      window.location.href = "https://wa.me/919497351075";
    }


  };
  return (
    <div
      className={`flex w-full ${bgproduct} ${tag ? "min-h-[600px]" : "min-h-[450px]"
        } flex-col-reverse gap-2 px-12 md:px-20 ${isHero ? "pt-20  md:py-40 lg:py-40 xl:py-40" : ""
        }   ${flex ? "md:flex-row-reverse" : "md:flex-row"}
       xl:px-36 ${bg}  `}
    >
      <div
        className={`${isHero ? "animate-fade-in" : ""
          } flex-1 flex-col min-h-[250px]  flex items-start justify-center md:min-h-full gap-2 2xl:pl-40`}
      >
        {tag && (
          <div className="w-full mr-auto md:mt-3 md:mb-2 relative">
            {showTagLine !== "no" && <div className={`absolute border-t border-t-[1px] bordr-t-[white] ${borderBlack} w-[400px] top-[50%] left-[-410px] z-[100000000000000000]`}>

            </div>}
            <span className={` text-[10px] md:text-[12px]  text-[white] ${textCo} ${textC} `}>
              {tag}
            </span>
          </div>
        )}

        <div className=" flex flex-col justify-center md:max-h-[60%] gap-4 md:gap-8 md:my-4">
          <h1
            className={`relative text-[white]  ${textCo} flex flex-wrap w-[100%] text-lg sm:text-3xl font-inter  xl:text-5xl  ${tag ? "font-extrabold" : "font-extrabold"
              }`}
          >
            {title}
          </h1>

          <p
            className={`${isHero ? "w-[100%] sm:w-[80%] xl:w-[60%]" : "md:w-[80%]"
              } text-sm md:text-base md:min-h-16 font-inter  font-light text-[white] ${textCo} text-start lg:text-lg`}
          >
            {caption}
          </p>
        </div>
        <button
          className={`mb-16 md:mb-0 ${tag && !isHero
              ? "bg-black text-white hover:bg-white hover:shadow-lg hover:text-black"
              : " border-none rounded-full btn-shadow button-grad-text    "
            }    text-sm md:text-base lg:text-lg px-6  mt-4 md:mt-8 py-2 md:py-4 font-medium ${isHero ? "md:px-12" : "md:px-20"
            } `}
          onClick={() => onClickHandler(isHero)}
        >
          {isHero ? "Buy now" : "Explore"}
        </button>

      </div>
      <div className="flex flex-1 min-h-[300px]  md:py-20  justify-center items-center md:min-h-full">
        <div
          className={`relative flex flex-col  ml-4  ${isHero ? "" : imgBg
            } justify-center items-center  rounded-full duration-300 ${tag
              ? "h-[250px] w-[250px] md:h-[300px] md:w-[300px] lg:h-[450px] lg:w-[450px]"
              : "h-[250px] w-[250px] lg:h-[400px] lg:w-[400px]"
            }`}
        >
          {isHero && (<div className={`${prdtType == "lora" ? "sec1lora" : ""}`}></div>)}
          {isHero && (<div className={`${prdtType == "lora" ? "third1lora" : ""}`}></div>)}
          <img
            src={image}
            alt={tag}
            loading="lazy"
            className={`relative z-100  ${imgSize === "lg" ? "w-[100%] xl:w-[100%]  ml-4 mb-8 md:ml-12 md:mb-20" : "w-[70%]"} ${combo && "w-[100%]"}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
