import { useEffect, useRef } from "react";
import {
  faqs,
  ProductSetUps,
  productSpecs,
  singleProductInfo,
} from "../../constants";
import Banner from "../Banner";
import FAQ from "../FAQ";
import BrochureCatalogue from "../products/BrochureCatalogue";
import OtherProducts from "../products/OtherProducts";
import ProductSetUp from "../products/ProductSetUp";
import ProductSpec from "../products/ProductSpec";
import pdf from "../../assets/brochures/MV 2_AUTOMATIC WATER level CONTROLLER_english.pdf";
import NavbarDark from "../Navbar/NavbarDark";

const WTLC = ({setvisible, activeIndex, setActiveIndex}) => {
  setvisible(false)
  const elemRef = useRef(null);

  useEffect(() => {
    elemRef.current.scrollIntoView();
  }, []);

  return (
    <div ref={elemRef}>
      <div className="absolute w-full top-0 z-[110]">
        <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
      <Banner product={singleProductInfo[1]} bgproduct="bg-2" />
      
      <ProductSetUp product={ProductSetUps[1]} />
      <ProductSpec product={productSpecs[0]} />
      <ProductSpec product={productSpecs[2]} isFlex />
      <BrochureCatalogue
        file={pdf}
        name="AUTOMATIC WATER level CONTROLLER Brochure.pdf"
      />
      <OtherProducts index={1} />
      <FAQ faq={faqs[0]} />
    </div>
  );
};

export default WTLC;
