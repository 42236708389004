import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import hamburger from "../../assets/hamburger.svg";
import { useState } from "react";
const Navbar = ({ activeIndex, setActiveIndex }) => {
  const navigate = useNavigate()
  const handleNavLinkClick = (e, index, path) => {
    e.preventDefault();
    console.log(path)
    setActiveIndex(index);
    navigate(path)
  };
  const navbarItems = [
    {
      item: "Home",
      path: "/",
    },
    {
      item: "About Us",
      path: "/about",
    },
    {
      item: "Products",
      path: "/products",
    },
    {
      item: "Dealership Enquiry",
      path: "/dealershipenquiry",
    },
    {
      item: "Contact Us",
      path: "",
    },
  ];
  const [showNavbar, setShowNavbar] = useState(false)
  return (
    <>
     {showNavbar && 
     <div className=" lg:hidden w-[100vw] h-[100vh] bg-[#0000005e] absolute z-[200]">

      <ul className=" bg-[white] relative w-[70%] fixed top-20 left-[50%] translate-x-[-50%] shadow-xl px-8 py-8 text-center flex flex-col gap-4 z-[150]">
      <span className="text-[black] absolute top-2 right-4 font-bold z-[201]" onClick={()=>setShowNavbar(false)}>X</span>

        {navbarItems.map((itm, index) => (
          <Link onClick={(e) => handleNavLinkClick(e, index, itm.path)}>
            <li  key={itm.index}>{itm.item}</li>
          </Link>
        ))}
      </ul></div>}
      <div className="w-[100vw] md:w-full nav relative pl-4 md:pl-20 py-6 md:py-10 ">
        <img src={logo} className="w-[20%] md:w-[10%] lg:w-auto" />
        <img src={hamburger} className="absolute right-6 top-9 md:top-12 md:right-12 lg:hidden w-auto md:w-[30px] " onClick={()=>setShowNavbar(!showNavbar)}></img>
        <ul className="bg-[#E8E9EC] width-max hidden lg:flex text-[#9F9F9F] poppins absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] flex px-2 rounded-full py-1">
          {navbarItems.map((itm, index) => (
            <Link 
              onClick={(e) => handleNavLinkClick(e, index, itm.path)}
              className={` ${
                activeIndex === index ? "active" : ""
              } cursor-pointer`}
            >
              <li className="py-1 px-4" key={itm.index}>
                {itm.item}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
            };

export default Navbar;
