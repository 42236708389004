import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const FAQ = ({ faq }) => {
  const [openItems, setOpenItems] = useState([])
  const handleToggle = (idx) => {
    const updatedOpenItems = [...openItems]
    updatedOpenItems[idx] = !updatedOpenItems[idx];
    setOpenItems(updatedOpenItems);

  }
  return (
    <>
      <div className="w-full flex items-center screenxl  md:justify-start md:pt-20">
        <h1 className="border-b-primary-green  border-b-[4px] h-[50px] text-2xl md:text-3xl font-bold md:text-center md:w-[100px]">
          FAQ's
        </h1>
      </div>
      <div className="w-full screenxl flex flex-col justify-between items-center pt-12  md:pt-20 md:pb-40">
        {faq.map(({ q, a }, idx) => (
          <div className="w-[90%] mb-6 border-b pb-4 md:pb-8">
            <div className="flex gap-4 md:gap-8">
            <span><AiOutlinePlus color="#0BFFC2" className={`${openItems[idx] && "hidden"} md:text-2xl `} onClick={()=>handleToggle(idx)}/><AiOutlineMinus onClick={()=>handleToggle(idx)} color="#0BFFC2"  className={`${!openItems[idx] && "hidden"} md:text-2xl`}/></span>
            <p className="text-[12px] md:text-[20px] font-semibold">{q}</p>
            </div>
            {openItems[idx] && (
            <p className="text-[11px] md:text-[16px] font-semibold w-[90%] my-2 md:my-8 pl-8 md:pl-20">{a}</p>
          )}
          </div>


         /*  <div
            key={idx}
            tabIndex={0}
            className="collapse collapse-plus border-b border-base-300 bg-base-100 w-[90%] md: w-[70%] my-4 outline-none"
          >
            <div className="collapse-title text-sm md:text-xl font-medium ">
              {q}
            </div>
            <div
              className="collapse-content text-sm font-light md:text-lg"
              tabIndex={0}
            >
              <p className="text-sm md:text-base">{a}</p>
            </div>
          </div> */
        ))}
      </div>
    </>
  );
};

export default FAQ;
