import React, { useEffect, useRef, useState } from "react";
import Combo from "../Combo";
import { Products } from "../products/Products";
import Slider from "../Slider/Slider";
import Navbar from "../Navbar/Navbar";


import { HomeSliderInfo } from "../../constants";

import motcon from "../../assets/MOTCON.png";
import lorawwlc from "../../assets/lorawwlc.png";
import vector1 from "../../assets/icons/Vector.png";
import vector2 from "../../assets/icons/Vector (1).png";
import vector3 from "../../assets/icons/icon-park-solid_protect.svg";
import customised from "../../assets/customised.png";
import research from "../../assets/research.png";
import motconimg from "../../assets/motcon.png";
import daynight from "../../assets/daynight.png";

import power from "../../assets/powerit.png";
import why1 from "../../assets/satisfaction.png";
import why2 from "../../assets/idea.png";
import why3 from "../../assets/high-quality.png";
import why4 from "../../assets/target.png";
import { Link, useNavigate } from "react-router-dom";





const Home = ({ setView,setvisible, activeIndex, setActiveIndex }) => {
 /*  setvisible(true)
  const elemRef = useRef(null);
  const comboRef = useRef(null);
 */
const navigate = useNavigate();



  const [isCustomElementAtTop, setIsCustomElementAtTop] = useState(false);
  const [isMotconConAtTop, setIsMotconConAtTop] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const customElement = document.querySelector(".customized");
      const motconcon = document.querySelector(".motcon-con");
      const rect = customElement.getBoundingClientRect();
      const rect1 = motconcon.getBoundingClientRect();
      const motcontop = rect1.top;
      const elementTop = rect.top;

      if (elementTop <= 0) {
        setIsCustomElementAtTop(true);
      } else {
        setIsCustomElementAtTop(false);
      }
      if (motcontop <= 0) {
        setIsMotconConAtTop(true);
      } else {
        setIsMotconConAtTop(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(()=>{
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % 3);
      
    }, 4000);
    return () => {
      clearInterval(interval)
    }
  },[currentIndex])

  const whyus = [
    {
      title: "Expertise",
      desc:"We're not just following trends; we're setting them. Our products are engineered with the latest advancements, ensuring you stay ahead of the curve.",
      img:why1
    },
    {
      title: "Innovation",
      desc:"We're not just following trends; we're setting them. Our products are engineered with the latest advancements, ensuring you stay ahead of the curve.",
      img:why2
    },
    {
      title: "Quality",
      desc:"Quality is non-negotiable for us. Each product is crafted with precision and undergoes rigorous testing to ensure peak performance and longevity.",
      img:why3
    },
    {
      title: "Customer-Centric",
      desc:"Your satisfaction is our priority. We're here to listen, adapt, and deliver solutions that enhance your daily life.",
      img:why4
    }
  ]
  const handleHomePrdtNavigation = (e,path) => {
    e.preventDefault()
    navigate(path)
  }
  return (
    <>
    <div className="w-[100%] h-[100vh] hidden sm:block lg:hidden bg-home relative">
      <Navbar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      <div>
      {
        HomeSliderInfo?.map((itm, index)=>(
          
          <div className={`${index === currentIndex? "opacity-1":"opacity-0"} relative h-full w-full transi`} key={index}>
            <div className="absolute">
            <div className="relative w-full left-[50%] top-[30%] sm:top-[20%] translate-x-[-50%]">
              <img src={motcon} alt="" className="absolute w-[80%] left-[50%] translate-x-[-50%] top-[30%]"/>
              <div className=" flex justify-center items-center relative">
              <div class="third"></div>
    
              <div class="sec"></div>
              <div class="first"></div>
    
              <img src={itm.image} alt="" className="relative z-[100] w-[60%] sm:w-[40%]" />
            </div>


            </div>
            <div className="px-12 pt-28 sm:pt-20">
              <h1 className="mont font-semibold text-3xl sm:text-2xl">{itm.title}</h1>
              <p className="poppins font-light text-lg sm:text-base my-8">{itm.desc}</p>
              <Link onClick={(e)=> handleHomePrdtNavigation(e)} >              <button className="text-[#0BFFC2] mont bg-[black] px-10 rounded-full py-3 ">Explore</button>
</Link>
            </div>
            </div>

          </div>
        ))
      }
      </div>

    </div>
    <div className="w-[100%] h-[100vh] bg-home relative sm:hidden lg:block ">
    <div className="fixed w-full z-[110] ">
        <Navbar activeIndex={activeIndex} setActiveIndex={setActiveIndex} />

      </div>
      <div className="hidden lg:block">
      {HomeSliderInfo?.map((itm, index)=>(
          <div className={`${index === currentIndex? "homeSliderFadeIn ":"homeSliderFadeOut"} transi`} key={index}>
          
         
    
          <div className="flex xl:gap-[2%] items-center  justify-between fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full">
            <div className="w-[36%] mt-[18%] pl-20 xl:pl-20 flex flex-col gap-4 items-start">
              <button onClick={(e)=> handleHomePrdtNavigation(e, itm.path)} className="text-[#0BFFC2] mont bg-[black] px-8 xl:px-10 rounded-full py-3 ">
                Explore
              </button>
              <h1 className="mont font-semibold text-2xl xl:text-3xl">
                {itm.title}
              </h1>
              <p className="poppins font-light">
                {itm.desc}
              </p>
            </div>
            <div className=" absolute left-[50%] translate-x-[-50%] top-0 2xl:w-[25%]  flex justify-center items-center ">
              <div class="first "></div>
    
              <div class="sec"></div>
              <div class="third"></div>
    
              <img src={itm.image} alt="" className="relative z-[100] w-[70%] 2xl:w-[100%] 2xl:mt-[-50px]" />
            </div>
            <div className="w-[35%] xl:w-[40%] mt-[20%]  xl:pl-20 ">
              <ul className="poppins font-light flex text-sm xl:text-lg flex-col gap-4">
                <li className="flex items-center ">
                  <div className="w-[15%]">
                  <div className="bg-black rounded-full  w-12 h-12 flex justify-center items-center">
                    <img src={vector1} className="   " />{" "}
                  </div>
                  </div>
                 
                  <span className="w-[80%]">{itm.points[0]}</span>
                </li>
                <li className="flex items-center">
                  <div className="w-[15%]">
                  <div className="bg-black rounded-full w-12 h-12 flex justify-center items-center">
                    <img src={vector2} className="   " />{" "}
                  </div>
                  </div>
                  <span className="w-[80%]">{itm.points[1]}</span>
                </li>
                <li className="flex items-center ">
                  <div className="w-[15%]">
                  <div className="bg-black rounded-full w-12 h-12 flex justify-center items-center">
                    <img src={vector3} className="   " />{" "}
                  </div>
                  </div>
                  <span className="w-[80%]">{itm.points[2]}</span>
                </li>
              </ul>
            </div>
          </div>
          </div>
      ))

      }
      </div>
      <div className="block lg:hidden">
      {HomeSliderInfo?.map((itm, index)=>(
          <div className={`${index === currentIndex? "opacity-1":"opacity-0"}  transi`} key={index}>
          <div className="fixed h-full w-full flex justify-center items-center ">
            <img src={motcon} alt="" className="w-[90%] mt-[-150px] " />

          </div>
          <div className="flex flex-col-reverse top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] fixed  w-full">
            <div className="w-[100%] mt-[18%] px-4 flex flex-col gap-4 items-start">
              
              <h1 className="mont font-semibold text-base">
                {itm.title}
              </h1>
              <p className="poppins text-xs font-light">
                {itm.desc}
              </p>
             <Link onClick={(e)=> handleHomePrdtNavigation(e)} > <button className="text-[#0BFFC2] text-xs mont bg-[black] px-10 rounded-full py-3 ">
                Explore
              </button></Link>
            </div>
            <div className="w-[100%] flex justify-center items-center relative">
              <div class="first"></div>
    
              <div class="sec"></div>
              <div class="third"></div>
    
              <img src={itm.image} alt="" className="relative z-[100] w-[70%]" />
            </div>
           
          </div>

         
          </div>
      ))

      }
      </div>
      
    </div>
    <div className="bg-[white] relative z-[110] ">
      <div
        className={` inter font-extrabold text-[#f3f3f3] text-[40px] xs:text-[50px] sm:text-[60px] md:text-[70px] lg:text-[90px] 2xl:text-[100px] 3xl:text-[150px] px-4 xxs:px-8 xs:px-12 mdl:px-20 xl:px-40 w-full py-8 lg:py-20 xl:py-32  sticky top-0 z-[112] ${
          isCustomElementAtTop ? "direct-con" : ""
        }`}
      >
        <span className="block">
          <span className="text-[#2b2b2b] mr-4 xxs:mr-10 xs:mr-8 md:mr-16 3xl:mr-8 "> We</span> direct,
        </span>
        <span className="pl-[4.5rem] xxs:pl-[5.5rem] xs:pl-[6rem] sm:pl-[7.5rem] md:pl-[9.5rem] lg:pl-48 2xl:pl-60 3xl:pl-72 xs:my-[-20px] lg:my-[-25px] 2xl:my-[-40px] 3xl:my-[-70px] block">design,</span>
        <span className="pl-20 xxs:pl-[6.5rem] xs:pl-28 sm:pl-40 md:pl-44 lg:pl-56 2xl:pl-80 block xs:mt-4 md:mt-0 text-[30px] md:text-[70px] lg:text-[90px] 2xl:text-[100px] 3xl:text-[150px] mb-[-30px] lg:mb-[-40px] 3xl:mb-[-70px]">and develop</span>
        <span className="text-[16px] md:text-[30px] lg:text-[35px] 2xl:text-[50px] 3xl:text-[70px]  pl-28 xxs:pl-32 xs:pl-36 sm:pl-[14rem] md:pl-[15rem] lg:pl-[18rem]  2xl:pl-[25rem] 3xl:pl-[35rem]   ">
          Your Smart Tomorrow
        </span>
      </div>
      <div className="blackdiv   bg-[black] sticky top-0 rounded-l-full relaitve z-[111]  "></div>
      <div className="text-[white] shadow-1 bg-[black] mt-32 md:mt-80  lg:px-28 inter leading-[28px] md:leading-[48px] py-16 px-8 md:py-32 text-center text-sm md:text-xl xl:text-2xl relative z-[115] ">
        <p className="font-light">
          We're at the forefront of revolutionizing the way you interact with
          your living space. Our cutting-edge home automation products are
          designed to simplify your life, enhance energy efficiency, and
          provide unmatched convenience. Explore our range of intelligent
          solutions that bring your home to life.
        </p>
       <Link to="/products"> <button className="border border-[#03A0FF] px-6 py-1 mt-12 md:mt-16 rounded-full ">
          Explore
        </button></Link>
        <img src={customised} alt="" className="customized w-[70%] md:w-[40%] mt-28" />
        <p className="text-left leading-[30px] xl:leading-[36px] w-[100%] lg:w-[90%] xl:w-[70%]  my-8 md:my-12 font-light">
          We believe in making your dreams a reality. We specialize in
          tailoring our home automation products to your unique requirements.
          If you have a specific challenge or need, our team of experts is
          ready to craft a customized solution that perfectly fits your
          lifestyle.
        </p>
      </div>
      <div className="bg-[white] relative z-[115] pt-12 md:pt-20 md:pb-28 lg:pb-40 xl:pb-80 px-8 xl:px-20">
        <div className="flex justify-end">
          <img src={research} className="w-[100%] md:w-[60%] " />
        </div>
        <div className="flex justify-end">
          <p className="inter leading-[28px] md:leading-[30px] text-right w-[100%] lg:w-[90%] xl:w-[80%] text-sm md:text-xl xl:text-2xl my-12">
            Our passion for innovation drives us to take on exciting projects
            that require research and development. From pushing the boundaries
            of home automation to tackling new challenges, we're always up for
            creating something groundbreaking.
          </p>
        </div>
      </div>
      {!isMotconConAtTop && (
        <div className="bg-[white] border border-t-[#eeeeee] md:bg-[#ffffff42] flex justify-center px-12 md:px-0 py-20 md:py-40 relative z-[115] sticky top-0">
          <img src={motconimg} alt="" />
        </div>
      )}
      <div className="relative z-[115] grad-1 flex flex-col lg:flex-row  py-32 md:py-40 xl:py-60 ">
        <div className="w-[100%] lg:w-[50%] flex justify-center items-center relative motcon-con ">
          
          <img
            src={lorawwlc}
            alt=""
            className="w-[80%] xs:w-[50%] relative mt-[-90px]  md:mt-[-140px] ml-[10px] md:ml-[40px]"
          />
        </div>
        <div className="w-[100%] lg:w-[50%] mt-24 lg:mt-0 flex flex-col px-8 xs:px-20 lg:px-0 gap-2 md:gap-8 items-start">
          <div>
            <span className="poppins font-bold text-[white] text-xs md:text-xl">
              MOTCON V2
            </span>
          </div>
          <h1 className="poppins font-bold heading-lora text-xl md:text-3xl lg:text-4xl w-[100%] lg:w-[90%] 2xl:w-[60%]">
            LoRa-WIRELESS WATER LEVEL CONTROLLER
          </h1>
          <p className="inter text-[white] text-sm md:text-xl w-[100%] my-4 md:my-0 lg:w-[90%] 2xl:w-[50%]">
            Stay in control, no matter the distance - with our LoRa based
            wireless water level controller
          </p>

          <Link to="/products/lora-wireless-water-tank-level-controller"><button className="poppins bg-[white] prdt-button px-10 font-medium text-sm md:text-base py-3 rounded-full">
            Explore
          </button></Link>
        </div>
      </div>

      <div className="bg-[white] md:bg-[#ffffff42] flex justify-center px-12 md:px-0 py-20 md:py-40 relative z-[115] sticky top-0">
        <img src={power} alt="" />
      </div>

      <div className="relative z-[115] grad2 flex flex-col lg:flex-row  py-32 md:py-40 xl:py-60 ">
        <div className="w-[100%] lg:w-[50%] flex justify-center items-center relative ">
        
          <img
            src={daynight}
            alt=""
            className="w-[80%] xs:w-[50%] relative mt-[-90px]  md:mt-[-140px] ml-[10px] md:ml-[40px]"
          />
        </div>
        <div className="w-[100%] lg:w-[50%] mt-24 lg:mt-0 flex flex-col px-8 xs:px-20 lg:px-0 gap-2 md:gap-8 justify-center items-start">
          <div>
            <span className="poppins font-bold text-[white] text-xs md:text-xl">
              MOTCON V2
            </span>
          </div>
          <h1 className="poppins font-bold heading-lora text-xl md:text-3xl lg:text-4xl w-[100%] lg:w-[90%] 2xl:w-[60%]">
          DAY-NIGHT SENSOR
          </h1>
          <p className="inter text-[white] text-sm md:text-xl w-[100%] my-4 md:my-0 lg:w-[90%] 2xl:w-[50%]">
          Experience the convenience and efficiency of automatic lighting
          </p>

         <Link to="/products/day-night-sensor"> <button className="poppins bg-[white] prdt-button px-10 font-medium text-sm md:text-base py-3 rounded-full">
            Explore
          </button></Link>
        </div>
      </div>

      <div className="bg-[#F3F3F3] inter relative z-[116] py-20 lg:py-40">
        <h1 className="text-3xl md:text-5xl font-bold text-center">Why Choose Us</h1>
        <div className=" mt-20 md:mt-0 pt-28 lg:pt-40 flex flex-col sm:flex-row flex-wrap justify-center ">
          {whyus.map((itm)=>(
          <div className="text-center w-[100%] sm:w-[45%] flex flex-col gap-4 mb-20 md:mb-40">
            <div className="w-full flex justify-center">
              <div className="bg-[white] shadow-xl w-[25%] xs:w-[20%] sm:w-[30%] md:w-[25%] lg:w-[20%] xl:w-[15%] rounded-full p-6">
                <img src={itm.img} alt="" className="w-[100%] " />
              </div>
            </div>
            <h1 className="font-bold text-xl md:text-3xl">{itm.title}</h1>
            <p className="text-sm md:text-xl w-[80%] mx-auto">{itm.desc}
            </p>
          </div>))}

          
          
        </div>
      </div>
    </div>
  </>

   
  );
};

export default Home;
