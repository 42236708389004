import { comboInfo } from "../../constants";
import Product from "../products/Product";
import combo from "./../../assets/images/combo.png";
import mobcombo from "../../assets/images/mobcombobg.png";
const ComboOffer = () => {
  return (
    <div className="relative min-h-screen">
      <img
        src={mobcombo}
        alt="combo offer"
        loading="lazy"
        className="w-[100%] md:hidden"
      />

      <div className="flex w-full bg-grey-one gap-2 flex-col md:flex-row bg-all pt-12 md:pt-52 pb-12 md:pb-44 ">
        <div className="flex  w-full flex-1    justify-center items-center md:min-h-full px-4 relative">
          <div className="relative flex flex-col justify-center items-center ">
            <img
              src={combo}
              alt="combo offer"
              loading="lazy"
              className="w-[100%] hidden md:block"
            />
          </div>
        </div>
        <div className=" flex-1 flex-col  px-8  flex items-start justify-center  gap-2 lg:pl-40">
          <div className=" flex flex-col gap-4 justify-around ">
            <h1 className="relative text-black flex flex-wrap text-2xl lg:text-5xl  md:leading-normal font-semibold ">
              ALL YOU NEED IN <br />
              ONE PACK
            </h1>
            <p className="w-full text-sm md:text-lg min-h-16 text-justify font-light md:text-xl">
              Simplify Your Life with Our Innovative <br />
              Combo Pack of Three Must-Have Devices
            </p>
          </div>
          <button className=" mt-4 border-none rounded-[5px] shadow-md bg-[#0BFFC2] text-black font-semibold hover:bg-white px-12 py-4 ">
            Buy Now
          </button>
        </div>
      </div>

      <hr className="mt-8 md:hidden" />

      <h1 className=" text-xl md:text-3xl w-full max-h-[100px] text-center font-medium pt-28 justify-self-end">
        Explore the Three Innovative Devices <br />
        in the Combo
      </h1>
      <div className="min-h-[1100px]  md:min-h-[700px] screenxl relative py-20 flex flex-col items-center justify-start ">
        <div className="flex flex-col lg:flex-row items-center justify-center w-full ">
          {comboInfo.map((offer, idx) => (
            <div
              className="w-full  flex items-center  flex-col p-4  "
              key={idx}
            >
              <div className="flex flex-col flex-1 min-h-[300px]  lg:min-h-[400px] gap-6  justify-center items-center md:min-h-full">
                <div
                  className={`relative flex flex-col ml-4 shad  justify-center items-center  rounded-full h-[250px] w-[250px] md:h-[300px] md:w-[300px] `}
                >
                  <img
                    src={offer.image}
                    alt={offer.title}
                    loading="lazy"
                    className={`w-[50%]
                     duration-300 hover:scale-90`}
                  />
                </div>
                <h3 className="text-sm text-center md:text-lg font-medium">
                  {offer.title}{" "}
                  <span className="text-gray-600 font-semibold text-xl">
                    <br></br>x <span className="text-2xl">{offer.qty}</span>
                  </span>
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative">
        {comboInfo.map((product, idx) => (
          <Product product={product} num={idx} key={idx} combo="combo" />
        ))}
        {/*  <div className="absolute right-0 bottom-[65%]  lg:bottom-[57%]">
          <img src={motcon} alt="power it" className="w-[200px] lg:w-[400px]" />
        </div>
        <div className="absolute left-0 bottom-[30%]  md:bottom-[25%] lg:bottom-[17%]">
          <img
            src={powerIt}
            alt="power it"
            className="w-[200px] lg:w-[400px]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default ComboOffer;
