import React from "react";
import { Products } from "../products/Products";
import NavbarDark from "../Navbar/NavbarDark";

const ProductsPage = ({setvisible, activeIndex, setActiveIndex}) => {
  setvisible(true)
  return (
    <div className="bg-[black]">
      <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>

      <h1 className="text-[white] font-bold text-2xl md:text-5xl text-center poppins my-12 md:my-20 heading-lora">PRODUCTS</h1>
    
    <Products />
    
    </div>


  )
  
};

export default ProductsPage;
