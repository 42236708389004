import React from "react";

const ProductSpec = ({ product: { title, image, specs }, isFlex = false }) => {
  return (
    <div
      className={`p-7 py-12 md:py-20 flex flex-col bg-[#F5F5F5] ${
        isFlex ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      <div className="flex lg:flex-1 flex-col items-center justify-center">
        
        <div className="flex w-full justify-center items-center">
          <img src={image} loading="lazy" alt={title} className="w-[70%]" />
        </div>
      </div>
      <ul className="flex lg:flex-1 list-none flex-col items-center justify-around w-full h-full">
      <h1 className="w-[70%] max-h-[200px] text-center font-bold text-lg md:text-3xl mb-8">
          {title}
        </h1>
        {specs.map((spec, idx) => (
          <li className="shadow-lg w-[100%] md:w-[80%]   bg-[white] text-[10px] font-light md:text-base flex justify-between rounded px-4 md:px-12 py-4 font-medium mb-2 md:mb-0 md:m-3 duration-200 hover:bg-[black] prdtspec-text-grad">
            <p className="flex-1 ">{spec.feature}</p>
            <div className="flex-1 flex flex-col items-center justify-around">
              {spec.caption.map((cap, idx) => (
                <p key={idx} className="w-full text-end">
                  {cap}
                </p>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductSpec;
