import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import NavbarDark from "../Navbar/NavbarDark";
const Contact = ({ setvisible, activeIndex, setActiveIndex }) => {
  const initialState = {
    send: false,
    error: null,
  };
  const elemRef = useRef(null);
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(initialState);

  setvisible(false);
  useEffect(() => {
    elemRef.current.scrollIntoView();
  }, []);
  const formSubmissionHanlder = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      );
      setLoading(false);
      form.current.reset();
      setSend((prev) => {
        return { ...prev, send: true };
      });
      setTimeout(() => setSend(initialState), 2500);
    } catch (error) {
      console.log(error.text);
      setSend((prev) => {
        return {
          ...prev,
          error: error.text ? error.text : "Something went wrong!",
        };
      });
      setTimeout(() => setSend(initialState), 2500);
      form.current.reset();
    }
  };

  return (
    <div className="min-h-screen" ref={elemRef}>
             <div className="bg-black">
             <NavbarDark activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
             </div>

      <div className=" bg-black flex items-center justify-start py-48 screenxl">
        <h1 className="text-2xl md:text-4xl text-white font-semibold w-full text-center">
          BECOME A DEALER
        </h1>
      </div>
      <div className="py-12 px-60 w-full text-xs md:text-lg screenxl">
        <form
          ref={form}
          onSubmit={formSubmissionHanlder}
          className="flex gap-5 shad rounded-[10px] md:rounded-[30px] py-4 px-4 md:py-16 md:px-20 lg:py-28 lg:px-40 w-full  flex-wrap justify-start flex-col md:flex-row"
        >
          <div className="form-control w-full my-4">
            <label className="label" htmlFor="company_name">
              <span className="label-text font-medium">Company name</span>
            </label>
            <input
              type="text"
              placeholder="Company name"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full  rounded-md p-2"
              required
              id="company_name"
              name="company_name"
            />
          </div>
          <div className="form-control  md:w-[40%] my-4">
            <label className="label" htmlFor="person_number">
              <span className="label-text font-medium">
                Contact Person Number
              </span>
            </label>
            <input
              type="tel"
              placeholder="Contact Person Number"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-lg   p-2"
              required
              id="person_number"
              name="person_number"
            />
          </div>
          <div className="form-control md:w-[40%] my-4">
            <label className="label" htmlFor="mobile_number">
              <span className="label-text font-medium">Mobile Number</span>
            </label>
            <input
              type="tel"
              placeholder="Mobile Number"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-md  p-2"
              required
              id="mobile_number"
              name="mobile_number"
            />
          </div>
          <div className="form-control w-full my-4">
            <label className="label" htmlFor="email">
              <span className="label-text font-medium"> Email Address</span>
            </label>
            <input
              type="email"
              placeholder="Registration Email"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full  rounded-md p-2"
              required
              id="email"
              name="email"
            />
          </div>
          <div className="form-control w-full my-4">
            <label className="label" htmlFor="address">
              <span className="label-text font-medium">Registered Address</span>
            </label>
            <input
              type="text"
              placeholder="Registration Address"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full  rounded-md p-2"
              required
              id="address"
              name="address"
            />
          </div>

          <div className="form-control md:w-[40%] my-4">
            <label className="label" htmlFor="district">
              <span className="label-text font-medium">District</span>
            </label>
            <input
              type="text"
              placeholder="District"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-lg   p-2"
              required
              id="district"
              name="district"
            />
          </div>
          <div className="form-control md:w-[40%] my-4">
            <label className="label" htmlFor="state">
              <span className="label-text font-medium">State</span>
            </label>
            <input
              type="text"
              placeholder="State"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-md  p-2"
              required
              id="state"
              name="state"
            />
          </div>

          <div className="form-control md:w-[40%] my-4">
            <label className="label" htmlFor="pincode">
              <span className="label-text font-medium">Pincode</span>
            </label>
            <input
              type="number"
              placeholder="Pincode"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-lg   p-2"
              required
              id="pincode"
              name="pincode"
            />
          </div>
          <div className="form-control md:w-[40%] my-4">
            <label className="label" htmlFor="company_gst">
              <span className="label-text font-medium">Company GST NO.</span>
            </label>
            <input
              type="text"
              placeholder="Company GST NO."
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full rounded-md  p-2"
              required
              id="company_gst"
              name="company_gst"
            />
          </div>

          <div className="form-control w-full my-4">
            <label className="label" htmlFor="details">
              <span className="label-text font-medium">
                Add Additional Details
              </span>
            </label>
            <input
              type="text"
              placeholder="Add Additional Details"
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              className="w-full  rounded-md p-2"
              id="details"
              name="details"
            />
          </div>
          <div className="flex items-center justify-start  w-full  my-4 ">
            {loading ? (
              <button
                type="button"
                class="bg-[#0BFFC2] px-10 py-2 shad hover:bg-white rounded-[10px] flex gap-2 items-center justify-center"
                disabled
              >
                <AiOutlineLoading3Quarters className="animate-spin" />
                Processing...
              </button>
            ) : (
              <button
                type="submit"
                className="bg-[#0BFFC2] px-10 py-2 shad hover:bg-white rounded-[10px]"
              >
                submit
              </button>
            )}
          </div>
        </form>
      </div>
      {send.send && (
        <div className="toast z-[10000] ">
          <div className="alert bg-[#0BFFC2]">
            <div className="flex">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Email sent! Your Dealership Enquiry is on its way
            </div>
          </div>
        </div>
      )}
      {send.error !== null && (
        <div className="toast z-[10000] ">
          <div className="alert alert-error">
            <div className="flex">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Something is not correct.Please try again after
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
