import { productInfo } from "../../constants";
import Product from "./Product";

export const Products = () => {
  return (
    <div className="" >
    

      {productInfo.map((product, idx) => (
        <Product product={product} num={idx} key={idx}  showTagLine="no" textC="text-black bg-white rounded-full px-6 py-2 shadow-xl" textCo="text-black"/>
      ))}
    </div>
  );
};
