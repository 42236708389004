const Map = () => {
  return (
    <div className="App">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4649832506716!2d76.42539951508003!3d10.77565459232206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d8cc6b5cc56b%3A0x8dd7bc37851dff75!2sKINFRA%20INTEGRATED%20INDUSTRIAL%20PARK%20%26%20KINFRA%20DEFENCE%20PARK!5e0!3m2!1sen!2sin!4v1678069225984!5m2!1sen!2sin"
        width="800"
        height="600"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        className="hidden md:block"
        title="map"
      ></iframe>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4649832506716!2d76.42539951508003!3d10.77565459232206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d8cc6b5cc56b%3A0x8dd7bc37851dff75!2sKINFRA%20INTEGRATED%20INDUSTRIAL%20PARK%20%26%20KINFRA%20DEFENCE%20PARK!5e0!3m2!1sen!2sin!4v1678069225984!5m2!1sen!2sin"
        width="500"
        height="350"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        className="hidden sm:block md:hidden "
        title="map"
      ></iframe>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4649832506716!2d76.42539951508003!3d10.77565459232206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d8cc6b5cc56b%3A0x8dd7bc37851dff75!2sKINFRA%20INTEGRATED%20INDUSTRIAL%20PARK%20%26%20KINFRA%20DEFENCE%20PARK!5e0!3m2!1sen!2sin!4v1678069225984!5m2!1sen!2sin"
        width="330"
        height="300"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        className="block sm:hidden "
        title="map"
      ></iframe>
    </div>
  );
};

export default Map;
