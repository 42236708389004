import {
  BsWhatsapp,
  BsInstagram,
  BsLinkedin,
  BsFacebook,
} from "react-icons/bs";
import { Link } from "react-router-dom";
const Footer = ({ contactRef }) => {
  return (
    <div className="bg-[#141414] relative bottom-0 z-[120] md:mt-0 w-full screenxl">
      <div className=" grid w-full min-h-[500px] max-h-[800px] lg:pl-20 gap-3 pt-20 grid-rows-4 grid-cols-1 lg:grid-rows-1 lg:grid-cols-4 lg:min-h-[300px]">
        <div className="flex flex-col items-center lg:items-start justify-start ">
          <div className="flex flex-col gap-2">
            <h1 className="font-semibold text-lg text-white w-full text-center lg:text-justify">
              Saron Innovature Labs LLP
            </h1>
            <p className="w-full text-[#b0b0b0] text-center  lg:text-justify text-sm">
              ADM-04, Kinfra Industrial integrated <br /> Defence Park,
              Palakkad,
              <br /> Kerala - 679103
            </p>
          </div>
        </div>
        
        <div className="flex flex-col items-center lg:items-start justify-start ">
          <div className="flex flex-col gap-2">
            <h1 className="font-semibold text-lg text-white w-full text-center lg:text-justify">
              Products
            </h1>
            <Link to="/products/lora-wireless-water-tank-level-controller"><p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Wireless water level controller
            </p></Link>
            <Link to="/products/water-tank-level-controller-wired"><p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Water level controller
            </p>
            </Link>
            <Link to="/products/mobile-phone-operated-pump-controller">
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Mobile phone operated pump controller
            </p></Link>
            <Link to="/products/day-night-sensor">
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Day-Night Sensor
            </p></Link>
            <Link to="">
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Motion Sensor
            </p></Link>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start justify-center lg:justify-start ">
          <div className="flex flex-col gap-2">
            <h1 className="font-semibold text-lg text-white w-full text-center lg:text-justify">
              Company
            </h1>
            <Link to="/about">
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              About
            </p>

            </Link>
            <Link to="/dealershipenquiry">
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              Dealership enquiry
            </p>
            </Link>
            
          </div>
        </div>
        <div
          className="flex flex-col items-center lg:items-start justify-start "
          ref={contactRef}
        >
          <div className="flex flex-col gap-2">
            <h1 className="font-semibold text-lg text-white w-full text-center lg:text-justify">
              Get in Touch
            </h1>
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              <a
                href="mailto:support@saron.in"
                className="w-full flex items-center justify-start gap-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                support@saron.in
              </a>
            </p>
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              <a
                href="tel:+91 8111 88 89 33"
                className="w-full flex items-center justify-start gap-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                +91 8111 88 89 33
              </a>
            </p>
            <p className="w-full text-[#b0b0b0] text-center lg:text-justify text-sm">
              <a
                href="tel:+91 466 29 11 330"
                className="w-full flex items-center justify-start gap-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
                +91 466 29 11 330
              </a>
            </p>
            <Link to="https://wa.me/919497351075" className="text-white text-base my-2 rounded-[5px] px-4 py-2 border hover:bg-white hover:text-black">CHAT WITH US</Link>
          </div>
        </div>
      </div>
      <footer className="py-4 fleext-gray-500x flex-col gap-4 items-center justify-center ">
        <div className="py-4 flex flex-row justify-center gap-4 items-center text-white">
          <Link to="https://wa.me/919497351075"><BsWhatsapp className="duration-200 w-5 h-5 hover:cursor-pointer hover:text-gray-400" /></Link>
          <Link to="https://instagram.com/saronlabs?igshid=ZDdkNTZiNTM="><BsInstagram className="duration-200 w-5 h-5 hover:cursor-pointer hover:text-gray-400" /></Link>
          <Link to="https://www.linkedin.com/company/saroninnovaturelabs/"><BsLinkedin className="duration-200 w-5 h-5 hover:cursor-pointer hover:text-gray-400" /></Link>
          <Link to="https://www.facebook.com/profile.php?id=100090739600517&mibextid=ZbWKwL"><BsFacebook className="duration-200 w-5 h-5 hover:cursor-pointer hover:text-gray-400" /></Link>
        </div>
        <p className="text-sm md:text-base text-gray-500 w-full text-center mt-1">
          Copyright 2023 Saron Innovature Labs LLP
        </p>
      </footer>
    </div>
  );
};

export default Footer;
