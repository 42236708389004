import { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import About from "./components/pages/About";
import ComboOffer from "./components/pages/ComboOffer";
import Contact from "./components/pages/Contact";
import Daynight from "./components/pages/Daynight";
// import Contact from "./components/pages/Contact";
import Home from "./components/pages/Home";
import Lora from "./components/pages/Lora";
import Motion from "./components/pages/Motion";
import MPOPC from "./components/pages/MPOPC";
import ProductsPage from "./components/pages/ProductsPage";
import WTLC from "./components/pages/WTLC";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import logo from './assets/images/logopreloader.png'
import BarLoader from "react-spinners/BarLoader";

const App = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const contactRef = useRef(null);
  const setView = (ref) =>
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
    const [visible, setvisible] = useState(true)

    const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div>{loading?
    
      <div className="h-[100vh] w-[100vw] flex justify-center items-center flex-col gap-12">
      <img src={logo} className="w-[80px] md:w-[100px]"/>
      <BarLoader color={"#33ffd2"} loading={loading} size={6} />
    </div>
    
    
    :(
    <div className=" min-h-screen ">
                <ScrollToTop />


      <Routes>
        <Route path="/" element={<Home setView={setView} activeIndex={activeIndex} setActiveIndex={setActiveIndex} setvisible={setvisible}/>} />
        <Route path="/about" element={<About  setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>} />
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/combo-offer" element={<ComboOffer />} />
        <Route path="/products" element={<ProductsPage  setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>} />
        <Route path="/dealershipenquiry" element={<Contact setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>} />
        <Route
          path="/products/lora-wireless-water-tank-level-controller"
          element={<Lora setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>}
        />
        <Route
          path="/products/day-night-sensor"
          element={<Daynight setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>}
        />
        <Route
          path="/products/water-tank-level-controller-wired"
          element={<WTLC setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>}
        />
        <Route
          path="/products/mobile-phone-operated-pump-controller"
          element={<MPOPC activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>}
        />
        <Route
          path="/products/motion-sensor"
          element={<Motion setvisible={setvisible} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>}
        />
      </Routes>
      <Footer contactRef={contactRef} />
    </div>)}</div>
  );
};

export default App;
