import { useEffect, useRef } from "react";
import { singleProductInfo, ProductSetUps, faqs } from "../../constants";
import Banner from "../Banner";
import FAQ from "../FAQ";
import BrochureCatalogue from "../products/BrochureCatalogue";
import OtherProducts from "../products/OtherProducts";
import ProductSetUp from "../products/ProductSetUp";
import pdf from "../../assets/brochures/MV2_GSM.pdf";
import NavbarDark from "../Navbar/NavbarDark";
import Navbar from "../Navbar/Navbar";

const MPOPC = ({activeIndex, setActiveIndex}) => {
  const elemRef = useRef(null);

  useEffect(() => {
    elemRef.current.scrollIntoView();
  }, []);
  return (
    <div ref={elemRef}>
      <div className="absolute z-[110] w-full top-0">
        <Navbar activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      </div>
      <Banner product={singleProductInfo[2]} bgproduct="bg-3" textCo="text-black" borderBlack="border-t-[grey]"/>
      <ProductSetUp product={ProductSetUps[1]} />
      <BrochureCatalogue
        file={pdf}
        name="Mobile phone operated pump controller Brochure.pdf"
      />
      <OtherProducts index={2} />
      <FAQ faq={faqs[0]} />
    </div>
  );
};

export default MPOPC;
