import { useNavigate } from "react-router-dom";
import { otherProducts } from "../../constants";

const OtherProducts = ({ index }) => {
  const navigate = useNavigate();
  const onClickHandler = (path) => navigate(path);
  return (
    <div className="w-full bg-base-200 screenxl py-20  md:py-32 flex flex-col md:flex-row lg:flex-row flex-wrap items-center justify-center gap-5 mb-6">
      {otherProducts[index].map((product, idx) => (
        <div
          onClick={() => onClickHandler(product.path)}
          key={idx}
          className=" grid grid-rows-[2fr,1fr] grid-cols-1 w-[300px] h-[350px] shadow-lg p-9 place-items-center m-3 cursor-pointer duration-300 bg-[white] hover:bg-base-100 rounded-md"
        >
          <div className="flex w-full justify-center items-center">
            <img
              src={product.image}
              alt={product.title}
              className="w-[60%]  "
            />
          </div>
          <h3 className="font-medium w-full text-center text-sm">
            {product.title}
          </h3>
        </div>
      ))}
    </div>
  );
};

export default OtherProducts;
